<template>
  <c-button
    v-chakra="{
      ':hover': {
        bg: 'rgba(0, 140, 129, 0.3)',
        color: 'primary.400',
      },
    }"
    px="0"
    py="4px"
    w="32px"
    h="32px"
    mx="4px"
    bg="transparent"
    color="darkGray.900"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot />
  </c-button>
</template>

<script>
export default {
  name: 'BtnPagination',
}
</script>
