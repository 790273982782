var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-button', _vm._g(_vm._b({
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        ':hover': {
          bg: 'rgba(0, 140, 129, 0.3)',
          color: 'primary.400'
        }
      },
      expression: "{\n    ':hover': {\n      bg: 'rgba(0, 140, 129, 0.3)',\n      color: 'primary.400',\n    },\n  }"
    }],
    attrs: {
      "px": "0",
      "py": "4px",
      "w": "32px",
      "h": "32px",
      "mx": "4px",
      "bg": "transparent",
      "color": "darkGray.900"
    }
  }, 'c-button', _vm.$attrs, false), _vm.$listeners), [_vm._t("default")], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }